import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/api'
import createPersistedState from 'vuex-persistedstate'
import { setCookies } from '@/utils/customCookies.js'
const TOKEN = process.env.VUE_APP_TOKEN

Vue.use(Vuex)

const persistedState = createPersistedState({
  paths: ['isLogin', 'token', 'userInfo', 'equityInformation'],
  key: 'ZZT_SSO',
  storage: window.localStorage
})

export default new Vuex.Store({
  namespaced: true,
  state: {
    isLogin: false,
    token: '',
    sessionValue:'',
    // userInfo: {
    //   username: '',
    //   avatar: ''
    // }
    userInfo: {},
    equityInformation: {}//权益信息
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload
    },
    setLogin(state, payload) {
      state.isLogin = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = payload
    },
    userLogout(state) {
      state.isLogin = false
      state.token = ''
      state.userInfo.username = ''
      state.userInfo.avatar = ''
    },
    setEquityInformation(state, payload) {
      state.equityInformation = payload
    }
  },
  actions: {
    login: async ({ commit }, data) => {
      // const { count, passWord } = data
      return new Promise((resolve, reject) => {
        api.login(data).then((res) => {
          const { data, success } = res
          if (success) {
            const { tokenValue } = data.data
            commit('setLogin', true)
            commit('setUserInfo', data.data)
            commit('setToken', tokenValue)
            setCookies({ [TOKEN]: tokenValue }, 12)
            // api.getPersonalRights().then(res => {
            //   commit('setEquityInformation', { ...res.data.data })
            // })
            resolve(tokenValue)
          } else {
            reject(data.message)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    loginBaseCode: async ({ commit }, data) => {
      const { telNumber, telCode } = data
      return new Promise((resolve, reject) => {
        api.loginCode(telNumber.trim(), telCode.trim()).then(({ data, success }) => {
          if (success) {
            const { tokenValue } = data.data
            commit('setLogin', true)
            commit('setUserInfo', data.data)
            commit('setToken', tokenValue)
            setCookies({ [TOKEN]: tokenValue }, 12)
            // api.getPersonalRights().then(res => {
            //   commit('setEquityInformation', { ...res.data.data })
            // })
            resolve(tokenValue)
          } else {
            //0033
            if (data.code === "0033") {
              reject('当前账户无PC端权益，如需使用，请点击右侧联系客服开通')
            } else {
              reject(data.message)
            }
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    logOut: async ({ commit }) => {
      await api.logout().then((resp) => {
        commit('userLogout')
        commit('setEquityInformation', {})
      })
    },
    coverLogOut: async ({ commit }) => {
      commit('userLogout')
      commit('setEquityInformation', {})
    },
    getUserInfo: async ({ commit }) => {
      const { data, success } = await api.getUserInfo()
      console.log('getUserInfo', data, success)
      if (success) {
        // const { username, avatar } = data.data
        commit('setLogin', true)
        // commit('setUserInfo', {
        //   username,
        //   avatar
        // })
        commit('setUserInfo', data.data)
      } else {
        commit('userLogout')
      }
    },
    updatePersonalRights: async ({ commit }) => {
      api.getPersonalRights().then(res => {
        commit('setEquityInformation', { ...res.data.data })
      })
    }
  },
  plugins: [persistedState],
  strict: true
})
