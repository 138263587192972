import axios from 'axios'
import store from '@/store'
import vue from "vue";
import router from "@/router";

// let baseURL = ['tryout', 'production'].includes(process.env.NODE_ENV) ? window.location.origin + '/api/' : process.env.VUE_APP_API_BASEURL
// axios.defaults.withCredentials = true; //设置默认请求带上cookie
const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL
  // baseURL
})

// 添加请求拦截器
request.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    // const { token, sessionValue } = store.state
    const { token } = store.state
    if (token) {
      config.headers = {
        ...config.headers,
        'token': token
      }
    }
    // if (sessionValue) {
    //   console.log('if sessionValue', sessionValue)
    //   config.headers = {
    //     ...config.headers,
    //     'SESSION': sessionValue
    //   }
    // }
    return config
  },
  (error) => {
    const { code, message, response } = error
    console.log(code, message)
    return Promise.reject(response.status) // 返回接口返回的错误信息
  })

request.interceptors.response.use(
  (response) => {
    const { data, config, headers } = response
    console.log('response', response)
    //针对验证码seeion做拦截处理
    // if (config.url === '/eams/outbound/captcha/getCaptcha') {
    //   if (headers?.['set-cookie']) {
    //     let headerSetCookies = config['set-cookie']
    //     const sessionMatch = headerSetCookies.match(/SESSION=([^;]+)/);
    //     const sessionValue = sessionMatch ? sessionMatch[1] : '';
    //     console.log('sessionValue', sessionValue)
    //     store.state.sessionValue = sessionValue
    //   }
    // }
    if (data?.code === '0001' && config.url !== '/eams/outbound/sys-users/ifValidToken') {
      vue.prototype.$message.error(data.message)
      store.dispatch('coverLogOut')
      router.push('/login')
    } else {
      return {
        data,
        success: data?.code === '0000'
      }
    }
  }
)

export default request
